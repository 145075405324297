import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const IndexPage = lazy(() => import('src/pages/BAIRU/dashboardBairu'));
const KioscosPage = lazy(() => import('src/pages/BAIRU/kioscos'));
const NegociosListPage = lazy(() => import('src/pages/BAIRU/negocios/list'));
const NuevoNegocioPage = lazy(() => import('src/pages/BAIRU/negocios/nuevo'));
const NegocioDetallesPage = lazy(() => import('src/pages/BAIRU/negocios/detalles'));
const SucursalDetallesPage = lazy(() => import('src/pages/BAIRU/negocios/sucursal'));

export const bairuRoutes = [
  {
    path: 'bairu',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'negocios',
        children: [
          { element: <NegociosListPage />, index: true },
          { path: 'nuevo', element: <NuevoNegocioPage /> },
          { path: ':id', element: <NegocioDetallesPage /> },
          { path: 'sucursal/:id', element: <SucursalDetallesPage /> },
        ],
      },
      { path: 'kioscos', element: <KioscosPage /> },
    ],
  },
];
